'use client';

import { ClerkProvider } from '@clerk/nextjs';
import { ThemeProvider } from '@components/theme-provider';
import { Toaster } from '@components/ui/sonner';
import clientLogger from '@lib/services/client/client-logger';
import '@styles/globals.css';
import { Inter } from 'next/font/google';
import { SWRConfig } from 'swr';

const inter = Inter({ subsets: ['latin'] });

const Authentication = ({
  children,
}: Readonly<{ children: React.ReactNode }>) => {
  return (
    <ClerkProvider
      appearance={{
        layout: {
          logoPlacement: 'inside',
        },
        variables: {
          colorPrimary: 'black',
        },
      }}
    >
      {children}
    </ClerkProvider>
  );
};

const Application = ({ children }: Readonly<{ children: React.ReactNode }>) => {
  return (
    <html lang='en' suppressHydrationWarning>
      <body className={`${inter.className} h-screen`}>
        <ThemeProvider
          attribute='class'
          defaultTheme='light'
          enableSystem
          disableTransitionOnChange
        >
          {children}
          <Toaster position='bottom-right' />
        </ThemeProvider>
      </body>
    </html>
  );
};

const ErrorHandling = ({
  children,
}: Readonly<{ children: React.ReactNode }>) => {
  const handleOnError = (error: any, key: any) => {
    if (error.status !== 403 && error.status !== 404) {
      // TODO: Add additional error handling logic
      clientLogger.error('Unexpected error occurred', { error, key });
    }
  };

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateIfStale: false,
        revalidateOnMount: true,
        onError: handleOnError,
      }}
    >
      {children}
    </SWRConfig>
  );
};

const RootLayout = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  return (
    <ErrorHandling>
      <Authentication>
        <Application>{children}</Application>
      </Authentication>
    </ErrorHandling>
  );
};

export default RootLayout;
