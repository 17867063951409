/**
 * For logging from web browser into web console and also into server console.
 */
import axios, { AxiosError } from 'axios';
import { v4 as uuid } from 'uuid';

type LogLevel = 'info' | 'warn' | 'error' | 'trace';

// Unique logging session for client
let sessionId = 'n/a';
const isSSR = typeof window === 'undefined';

if (!isSSR) {
  const id = window.sessionStorage.getItem('sessionId');
  if (id === null) {
    sessionId = uuid();
    window.sessionStorage.setItem('sessionId', sessionId);
  } else {
    sessionId = id;
  }
}

// Log from client to the server
// Note: Do not use this for verbose/debug logging as it will generate a lot of traffic.
const remoteLogger = {
  info: (message: string, ...params: any[]) => {
    log('info', message, params);
  },
  warn: (message: string, ...params: any[]) => {
    log('warn', message, params);
  },
  error: (message: string, ...params: any[]) => {
    log('error', message, params);
  },
  trace: (message: string, ...params: any[]) => {
    log('trace', message, params);
  },
};

// Logs to console and then sends the log to the server
const log = (level: LogLevel, message: string, ...params: any[]) => {
  const logData = {
    level,
    message,
    params,
    sessionId,
    timestamp: new Date().toISOString(),
  };

  console[level](message, params);

  if (isSSR) {
    console.warn(
      'Remote logger was called on server side. It should run on client side only.',
    );
  }

  axios.post('/api/log', logData).catch((error: Error | AxiosError) => {
    if (axios.isAxiosError(error)) {
      console.error(
        'Failed to log to the server',
        error.response?.status,
        error.response?.data,
      );
      return;
    }

    console.error('Failed to log to the server', error);
  });
};

export default remoteLogger;
